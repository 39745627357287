import React from 'react';
import Layout from '../components/Layout';

const DuesRecurringFormPage = () => {
    return (
        <Layout>
            <div className="container-fluid p-0" id="page-top">
                <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
                    <div className="w-100">
                        <h1 className="mb-0">Give <span className="text-primary">Today</span></h1>
                        <h2 className="mb-5">Lambda Iota Alumni Association</h2>
                        <div className="bg-red">
                            <p className="mb-5" align="center">Enter Payment Information Below</p>
                            <p className="mb-5" align="center">You selected:<br />
                                Giving Level: donationLevel<br />
                                Amount: donationAmount<br />
                                Your card will be billed donationFrequency<br />
                                Payments will continue until April 30, 2023
                            </p>

                            <form id="payment-form">
                                <input type="hidden" id="priceId" name="priceId" value="<%= priceId %>" />
                                <div className="form-group">
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="email" name="email"
                                        placeholder="email@example.com" />
                                </div>
                                <div id="card-element" className="input form-control">

                                </div>
                                <div className="form-group" align="center">
                                    <br />
                                    <button id="card-button" className="btn btn-success mb-2">Submit Payment</button>
                                </div>
                                <p id="payment-result">

                                </p>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
};

export default DuesRecurringFormPage;

/*

<script src="https://js.stripe.com/v3/"></script>

<script>
    const stripe = Stripe('<%= stripeKey %>');

    const elements = stripe.elements();
    const cardElement = elements.create('card', {
        style: {
            base: {
                backgroundColor: '#ffffff',
                iconColor: '#555',
                color: '#000',
                fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#fce883',
                },
                '::placeholder': {
                    color: '#555',
                },
            },
            invalid: {
                iconColor: '#FFC7EE',
                color: '#FFC7EE',
            },
        },
    });

    cardElement.mount('#card-element');

    const form = document.getElementById("payment-form");

    let resultContainer = document.getElementById('payment-result');

    const submitButton = document.getElementById("card-button");

    cardElement.on('change', (event) => {
        if (event.error) {
            showCardError(event.error.message);
        }
        else {
            clearCardError();
        }
    });

    function showCardError(message) {
        resultContainer.textContent = message;
    }

    function clearCardError() {
        resultContainer.textContent = '';
    }

    function disableSubmitButton() {
        submitButton.disabled = true;
        submitButton.textContent = "Processing";
    }

    function enableSubmitButton() {
        submitButton.disabled = false;
        submitButton.textContent = "Submit Payment";
    }

    form.addEventListener('submit', async event => {
        disableSubmitButton();
        const name = document.getElementById("name").value;
        const email = document.getElementById("email").value;
        const subscriptionPriceId = document.getElementById("priceId").value;
        event.preventDefault();
        clearCardError();

        // Start Subscription Flow here.

        const customerResult = await createCustomer(name, email, subscriptionPriceId);

        const customerResultJson = await customerResult.json();

        const paymentResult = await createPaymentMethod(cardElement, customerResultJson.customer.id, subscriptionPriceId);

        if (paymentResult.error) {
            displayError(error)
            return;
        }

        const subscriptionResult = await createSubscription({
            customerId: customerResultJson.customer.id,
            paymentMethodId: paymentResult.paymentMethod.id,
            priceId: subscriptionPriceId,
        });

        const subscriptionResultJson = await subscriptionResult.json();

        // If the card is declined, display an error to the user.
        if (subscriptionResult.status == 402) {
            // The card had an error when trying to attach it to a customer.

            showCardError(subscriptionResultJson.message);
            return; // All Done.
        }

        // Check here for successful - if successful, skip the rest
        if (subscriptionResultJson.status === 'active') {
            // We have success!

            console.log("Success!");
            await onSubscriptionComplete();
        }
        else {

            // Some payment methods require a customer to be on session
            // to complete the payment process. Check the status of the
            // payment intent to handle these actions.

            if (subscriptionResultJson.latest_invoice.payment_intent.status === 'requires_action') {
                const customerActionResult = await handleCustomerActionRequired({ paymentIntent: subscriptionResultJson.latest_invoice.payment_intent, paymentMethodId: paymentResult.paymentMethod.id });

                if (customerActionResult.error) {
                    enableSubmitButton();
                    showCardError(customerActionResult.error.message);
                    throw customerActionResult.error;
                }
                else {
                    if (customerActionResult.paymentIntent.status === 'succeeded') {
                        // We have success!

                        // Need to notify back-end here to trigger record updates

                        await onSubscriptionComplete();
                        return;
                    }
                    // Keep going - no success yet.
                }
            }
            else if (subscriptionResultJson.latest_invoice.payment_intent.status === 'requires_payment_method') {
                // If attaching this card to a Customer object succeeds,
                // but attempts to charge the customer fail, you
                // get a requires_payment_method error.
                enableSubmitButton();
                // Card was not accepted.
                showCardError('Your Card was declined.');
                throw { error: { message: 'Your card was declined.' } };
            }
            else {
                enableSubmitButton();
                showCardError('An undetermined error occurred.');
                throw { error: { message: 'An undetermined error occurred.' } };
            }
        }

        enableSubmitButton();
    });

    const createCustomer = async (name, email, priceId) => {
        return await fetch('/dues/customers', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name,
                email,
                priceId
            })
        });
    }

    const createPaymentMethod = async (cardElement) => {
        return await stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
    }

    const createSubscription = async ({ customerId, paymentMethodId }) => {
        return await fetch('/dues/subscriptions', {
            method: 'post',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify({
                customerId: customerId,
                paymentMethodId: paymentMethodId
            })
        });
    }

    const onSubscriptionComplete = async () => {
        // Payment was successful.
        // Change your UI to show a success message to your customer.
        // Call your backend to grant access to your service based on
        // `result.subscription.items.data[0].price.product` the customer subscribed to.
        window.location = "/dues-recurring-finished";
    }

    const handleCustomerActionRequired = async ({
        paymentMethodId,
        paymentIntent,
    }) => {
        return await stripe
            .confirmCardPayment(paymentIntent.client_secret, {
                payment_method: paymentMethodId,
            })
    }
</script> */